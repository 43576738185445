@import 'carbon-components/scss/globals/scss/styles.scss';

@font-face {
  font-family: 'ArchiaRegular';
  src: local('ArchiaRegular'), url(./styles/assets/fonts/Archia-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'ConcreticaRegular';
  src: local('ConcreticaRegular'), url(./styles/assets/fonts/Concretica-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}